<template>
  <v-dialog
    v-if="customDeliveryData"
    :attach="`#${$store.$containerId} .rttApp`"
    v-model="dialog"
    persistent
    :overlay="true"
    content-class="ma-5"
    max-width="500"
    width="100%"
  >
    <v-card v-if="!success" width="100%" class="card_dialog v-col-12">
      <v-btn
        icon="$close"
        variant="text"
        density="comfortable"
        class="close-button float-right"
        v-on:click="dialogHandler(dialogs.NEIGHBOUR)"
      />
      <v-card-title class="dialog-title">
        {{ $t('customization.neighbour.header') }}
      </v-card-title>
      <v-card-text class="px-0 py-2"
        ><div v-html="$t('customization.neighbour.description')"
      /></v-card-text>
      <v-form :ref="deliverToNeighbourForm" v-model="valid">
        <v-text-field
          :label="$t('customization.neighbour.fullName')"
          variant="outlined"
          required
          :rules="fullnameRules"
          v-model="customDeliveryData.fullName"
        ></v-text-field>

        <v-text-field
          bg-color="#fff"
          :label="$t('generic.description')"
          variant="outlined"
          v-model="customDeliveryData.description"
        ></v-text-field>
        <AlertComponent :message="message" />
        <div>
          <v-btn
            :block="true"
            type="submit"
            v-on:click.prevent="prepareUpdateCustomDeliveryData"
            :disabled="!valid"
            :loading="loading"
            class="bg-primary"
          >
            {{ $t('generic.save') }}
          </v-btn>
        </div>
      </v-form>
    </v-card>

    <v-card v-if="success" width="100%" class="v-col-12 px-4 py-5 text-center">
      <v-btn
        icon="$close"
        variant="text"
        density="comfortable"
        class="close-button float-right"
        v-on:click="dialogHandler(dialogs.NEIGHBOUR)"
      />
      <v-card-title class="dialog-title pa-0 text-center">
        {{ $t('customization.neighbour.success.header') }}
      </v-card-title>
      <v-card-text class="px-0 py-2">
        {{ $t('customization.neighbour.success.description') }}<br />
        {{ customDeliveryData.fullName }}<br />
        {{ customDeliveryData.description }}
      </v-card-text>
      <div>
        <v-form>
          <v-btn
            :block="true"
            type="submit"
            v-on:click.prevent="dialogHandler(dialogs.NEIGHBOUR)"
            class="bg-primary"
            >{{ $t('generic.okay') }}</v-btn
          >
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import {
  ACTION_CUSTOMIZED_DELIVERY,
  ACTION_TYPE_ERROR,
  ACTION_TYPE_INFO,
  DELIVER_TO_NEIGHBOUR,
  TOGGLE_DIALOG_EVENT
} from '@/store/constants';
import AlertComponent from '@/components/AlertComponent';
import { getUtmSource } from '@/router/queryParam.js';
import { dialogs } from '@/components/dialogs/dialogs.js';

export default {
  name: 'DeliverToNeighbourDialog',
  components: {
    AlertComponent
  },
  props: {
    open: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      deliverToNeighbourForm: null,
      deliveryData: {},
      fullnameRules: [
        v => !!v || this.$t('customization.neighbour.rules.fullNameRequired')
      ],
      loading: false,
      success: false,
      valid: true,
      dialogs
    };
  },
  computed: {
    ...mapState(['trackingData']),
    ...mapState(['message']),
    customDeliveryData: {
      get() {
        return this.deliveryData;
      },
      set(customizedDelivery) {
        if (
          customizedDelivery &&
          customizedDelivery.type === DELIVER_TO_NEIGHBOUR
        ) {
          this.deliveryData = cloneDeep(customizedDelivery);
        } else {
          this.deliveryData = {};
        }
      }
    },
    dialog: {
      get() {
        return this.open;
      }
    }
  },
  methods: {
    ...mapActions(['addCustomizedDelivery']),
    prepareUpdateCustomDeliveryData() {
      this.loading = true;
      const payload = {
        body: {
          type: DELIVER_TO_NEIGHBOUR,
          fullName: this.customDeliveryData.fullName,
          email: '',
          phone: '',
          description: this.customDeliveryData.description
        },
        utmSource: getUtmSource(this.$route),
        tenantId: this.$rttI18n.tenantId,
        postcode: this.trackingData.toAddress.postcode,
        trackingId: this.trackingData.trackingId
      };
      this.addCustomizedDelivery(payload);
    },
    dialogHandler(dialog) {
      this.$mitt.emit(TOGGLE_DIALOG_EVENT, dialog);
    }
  },
  watch: {
    message(message) {
      if (
        message.msg === ACTION_CUSTOMIZED_DELIVERY &&
        message.type === ACTION_TYPE_INFO
      ) {
        this.loading = false;
        this.success = true;
      }
      if (message.type === ACTION_TYPE_ERROR) {
        this.loading = false;
        this.success = false;
      }
    },
    trackingData() {
      this.customDeliveryData = this.trackingData.customizedDelivery;
    }
  }
};
</script>

<style scoped></style>
