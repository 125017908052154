<template>
  <footer class="mt-16 mt-md-12">
    <a
      :href="faqLink"
      @click="sendAnalytics(trackingData, 'clicksStats', 'faqLink')"
      target="_blank"
    >
      <img
        class="faq"
        alt="GLS Group FAQ"
        :src="publicPath(`/themes/${themeId}/icon_gls_faq.svg`)"
      />
    </a>
    <section
      :class="[
        'footer-container',
        config.disableImprintLink || config.disableContactLink
          ? 'footer-container-short'
          : 'footer-container-normal'
      ]"
    >
      <div>
        <router-link
          v-if="!config.disableImprintLink"
          :to="imprintLink"
          @click="sendAnalytics(trackingData, 'clicksStats', 'imprintLink')"
        >
          <span>{{ $t('imprint.name') }}</span>
        </router-link>

        <router-link
          v-if="privacyContentExist"
          :to="internalPrivacyLink"
          @click="
            sendAnalytics(trackingData, 'clicksStats', 'internalPrivacyLink')
          "
        >
          <span>{{ $t('privacy.name') }}</span>
        </router-link>

        <a
          v-else
          :href="privacyLink"
          @click="sendAnalytics(trackingData, 'clicksStats', 'privacyLink')"
          target="_blank"
          >{{ $t('privacy.name') }}</a
        >

        <a
          v-if="externalContactLink"
          :href="externalContactLink"
          @click="
            sendAnalytics(trackingData, 'clicksStats', 'externalContactLink')
          "
          target="_blank"
          >{{ $t('generic.contact') }}</a
        >

        <router-link
          v-else-if="!config.disableContactLink"
          @click="sendAnalytics(trackingData, 'clicksStats', 'contactLink')"
          :to="contactLink"
        >
          <span>{{
            $rttI18n.tenantSpecificWithFallback(
              'contact.label',
              'generic.contact'
            )
          }}</span>
        </router-link>
        <a
          v-if="$rttI18n.isGlsItSelected"
          :href="publicPath(`/themes/${themeId}/accessibility_report.pdf`)"
          @click="
            sendAnalytics(trackingData, 'clicksStats', 'accessibilityReport')
          "
          target="_blank"
        >
          {{ $t('accessibility.label') }}
        </a>
      </div>
    </section>
    <div class="subfooter bg-white">
      <div
        v-if="!$rttI18n.isGlsItSelected && trackingNote !== ''"
        class="v-container pb-0 text-md-left text-center"
      >
        <span class="font-weight-bold" v-html="trackingNote"></span>
      </div>
      <div
        class="v-container d-flex flex-wrap gap-4 pa-4 justify-center justify-sm-space-between powered-by"
      >
        <div v-if="!config.disablePoweredBy" class="d-flex align-center">
          <span>powered by</span>
          <a
            v-if="$rttI18n.isGlsItSelected"
            href="/"
            class="ml-2"
            title="Bettermile"
          >
            <img
              class="d-block"
              width="150px"
              alt="Bettermile"
              src="/bettermile_logo.svg"
            />
          </a>
          <a v-else href="/" class="ml-2" title="GLS">
            <img class="d-block" width="75px" alt="GLS" src="/gls_logo.svg" />
          </a>
        </div>
        <TrustPilotLink class="trustPilot" />
      </div>
    </div>
  </footer>
</template>

<script>
import { configGetter } from '@/config/configGetter';
import { mapGetters, mapState } from 'vuex';
import TrustPilotLink from '@/components/TrustPilotLink';
import { TRACKING_STATE_DELIVERED } from '@/store/constants';
import publicPath from '@/util/publicPath';
import { sendAnalytics } from '@/services/analytics.js';

export default {
  name: 'RttFooterComponent',
  methods: { sendAnalytics },
  components: {
    TrustPilotLink
  },
  data() {
    return {
      publicPath
    };
  },
  computed: {
    ...mapGetters(['getTrackingState']),
    ...mapState(['themeId', 'trackingData']),
    config() {
      return configGetter(this.$rttI18n.tenantId, this.themeId);
    },
    externalContactLink() {
      return this.$rttI18n.tenantSpecificContent('footer.contactLink');
    },
    privacyContentExist() {
      return this.$rttI18n.tenantSpecificContentExists('privacy.content');
    },
    trackingNote() {
      if (
        this.$rttI18n.tenantId &&
        this.trackingData.trackingId &&
        this.getTrackingState !== TRACKING_STATE_DELIVERED
      ) {
        return this.$rttI18n.tenantSpecificContent('glsTrackingNote', {
          trackingId: this.trackingData.trackingId,
          postcode: this.trackingData.toAddress?.postcode
        });
      }
      return '';
    },
    contactLink() {
      const routeName = this.$t(`languageSpecificRoutes.contact`);
      return `/${this.$rttI18n.tenantId ?? 'DE'}/${
        this.$rttI18n.locale
      }/${routeName}`;
    },
    faqLink() {
      return this.$rttI18n.tenantSpecificContentOrGeneric('faqLink');
    },
    imprintLink() {
      const routeName = this.$t(`languageSpecificRoutes.imprint`);
      if (this.$route.params.countryOrTenantId) {
        return `/${this.$route.params.countryOrTenantId}/${this.$rttI18n.locale}/${routeName}`;
      }
      return `/DE/${this.$rttI18n.locale}/imprint`;
    },
    privacyLink() {
      return this.$rttI18n.tenantSpecificWithFallback(
        'privacy.link',
        'glsGroup.privacy.link'
      );
    },
    internalPrivacyLink() {
      const routeName = this.$t(`languageSpecificRoutes.privacy`);
      if (this.$route.params.countryOrTenantId) {
        return `/${this.$route.params.countryOrTenantId}/${this.$rttI18n.locale}/${routeName}`;
      }
      return `/DE/${this.$rttI18n.locale}/privacy`;
    }
  }
};
</script>

<style scoped>
.faq {
  height: 50px;
  margin-top: -56px;
  position: absolute;
  right: 12px;
}

footer {
  /*noinspection CssUnresolvedCustomProperty*/
  background-color: rgb(var(--v-theme-footerBackground));
}
.footer-container {
  margin: 0 auto;
  padding: 34px 20px;
  text-align: center;
  transition: all 0.25s ease-in;
  width: 100%;
}
.footer-container a,
.footer-container a:visited {
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  padding: 0 50px;
  text-decoration: none;
}
.footer-container a {
  display: inline-block;
  border-right: 1px solid #fff;
  min-width: 220px;
}
.footer-container a:last-child {
  border-right: none;
}

.subfooter {
  border-top: 1px solid #666;
  box-shadow: 0 0 0 rgba(100, 100, 100, 0.2);
}

.powered-by {
  min-height: 58px;
}

@media (max-width: 700px) {
  .footer-container {
    padding: 20px;
  }
  .footer-container-normal {
    min-height: 350px;
  }
  .footer-container-short {
    min-height: 240px;
  }
  .footer-container a {
    display: block;
    margin: 40px;
  }
  .footer-container a {
    border-right: none;
  }
  .footer-container a::after {
    border-right: none;
    border-bottom: 1px solid #fff;
    content: '';
    display: block;
    height: 1px;
    margin: 40px auto;
    width: 40px;
  }
  .footer-container a:last-child::after {
    border-bottom: none;
  }
}
</style>
