<template>
  <div>
    <CustomizeDialog
      :open="this.dialogConfig[dialogs.CUSTOMIZE]?.show"
      :supportedCustomization="getTrackingData.supportedCustomizedDeliveryTypes"
      :smsNotification="getTrackingData.smsNotification"
      :pushNotification="getTrackingData.pushNotification"
    />
    <DeliverToNeighbourDialog
      v-if="getTrackingData"
      :open="this.dialogConfig[dialogs.NEIGHBOUR]?.show"
    />
    <DropOffDialog
      v-if="getTrackingData"
      :open="this.dialogConfig[dialogs.DROP_OFF]?.show"
    />
    <NotificationSubscriptionDialog
      v-if="getTrackingData"
      :open="this.dialogConfig[dialogs.NOTIFICATION_SUBSCRIPTION]?.show"
    />
    <ParcelShopDialog
      :open="this.dialogConfig[dialogs.PARCEL_SHOP]?.show"
      :parcelShopData="this.dialogConfig[dialogs.PARCEL_SHOP]?.payload"
    />
    <PushNotificationSubscriptionDialog
      v-if="getTrackingData"
      :open="this.dialogConfig[dialogs.PUSH_NOTIFICATION_SUBSCRIPTION]?.show"
    />
    <TimeFrameSelectionDialog
      v-if="getTrackingData && this.dialogConfig[dialogs.DELIVERY_PERIOD]?.show"
      :open="this.dialogConfig[dialogs.DELIVERY_PERIOD]?.show"
    />
    <TrustPilotConsentDialog
      :open="this.dialogConfig[dialogs.TRUST_PILOT_CONSENT_DIALOG]?.show"
    />
  </div>
</template>

<script>
import { dialogs } from '@/components/dialogs/dialogs.js';
import TimeFrameSelectionDialog from '@/components/dialogs/TimeFrameSelectionDialog.vue';
import CustomizeDialog from '@/components/dialogs/CustomizeDialog.vue';
import DeliverToNeighbourDialog from '@/components/dialogs/DeliverToNeighbourDialog.vue';
import DropOffDialog from '@/components/dialogs/DropOffDialog.vue';
import NotificationSubscriptionDialog from '@/components/dialogs/NotificationSubscriptionDialog.vue';
import PushNotificationSubscriptionDialog from '@/components/dialogs/PushNotificationSubscriptionDialog.vue';
import { mapGetters } from 'vuex';
import { TOGGLE_DIALOG_EVENT } from '@/store/constants.js';
import ParcelShopDialog from '@/components/dialogs/ParcelShopDialog.vue';
import TrustPilotConsentDialog from '@/components/dialogs/TrustPilotConsentDialog.vue';

export default {
  name: 'DialogHandler',
  components: {
    TrustPilotConsentDialog,
    CustomizeDialog,
    DeliverToNeighbourDialog,
    DropOffDialog,
    NotificationSubscriptionDialog,
    ParcelShopDialog,
    PushNotificationSubscriptionDialog,
    TimeFrameSelectionDialog
  },
  data() {
    return {
      dialogConfig: {},
      parcelShopData: undefined,
      dialogs
    };
  },
  computed: {
    ...mapGetters(['getTrackingData'])
  },
  methods: {
    toggleDialog(e) {
      const dialog = e.payload ? e.dialog : e;
      const dialogConfig = this.dialogConfig[dialog] || {};
      dialogConfig.show = !dialogConfig.show;
      dialogConfig.payload = e.payload;
      this.dialogConfig[dialog] = dialogConfig;
    }
  },
  mounted() {
    this.$mitt.on(TOGGLE_DIALOG_EVENT, this.toggleDialog);
  }
};
</script>

<style scoped></style>
