<template>
  <img
    v-if="showTrustPilotLink(trackingData)"
    class="trustPilotLink d-block"
    alt="TrustPilot Rating"
    src="/TrustPilot-Box.svg"
    @click="openTrustPilotDialog()"
  />
</template>

<script>
import { mapState } from 'vuex';
import { showTrustPilotLink } from '@/services/trustPilot';
import { dialogs } from '@/components/dialogs/dialogs.js';
import { TOGGLE_DIALOG_EVENT } from '@/store/constants.js';
import { sendAnalytics } from '@/services/analytics.js';

export default {
  name: 'TrustPilotLink',
  data: () => ({
    showTrustPilotLink,
    TOGGLE_DIALOG_EVENT
  }),
  computed: {
    ...mapState(['trackingData'])
  },
  methods: {
    openTrustPilotDialog() {
      sendAnalytics(this.trackingData, 'clicksStats', 'trustPilot');
      this.$mitt.emit(TOGGLE_DIALOG_EVENT, dialogs.TRUST_PILOT_CONSENT_DIALOG);
    }
  }
};
</script>

<style scoped>
.trustPilotLink {
  cursor: pointer;
  height: 50px;
}
</style>
